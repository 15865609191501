import { ScopedCssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { darkTheme } from "../darkTheme";

interface DarkThemeProps {
  children: JSX.Element;
}

const DarkTheme: React.FC<DarkThemeProps> = ({ children }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <ScopedCssBaseline>{children}</ScopedCssBaseline>
    </ThemeProvider>
  );
};

export default DarkTheme;
