import { Box, Button, Container, Typography } from "@mui/material";
import * as React from "react";
import { UseCaseText } from "../controller/UsesCases";

type UseCaseProps = {
  useCase: UseCaseText;
  imageSide: string;
};

const UseCase = ({ useCase, imageSide }: UseCaseProps) => {
  const imageLeft = imageSide == "left";
  return (
    <Container
      sx={{
        display: "flex",
        pb: 10,
        flexDirection: {
          md: imageLeft ? "row" : "row-reverse",
          xs: "column",
        },
      }}
    >
      <Box
        sx={{
          flex: 3,
          px: {
            xs: 0,
            md: 10
          },
          py: {
            xs: 5,
            md: 10
          },
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h3" mb={2}>
          {useCase.title}
        </Typography>
        <Typography variant="subtitle1" mb={2}>
          {useCase.description}
        </Typography>
        <Button href="https://manager.mintharbor.io/Login" variant="contained">
          {useCase.button}
        </Button>
      </Box>
      <Box
        sx={{
          flex: 2,
          display: "flex",
        }}
      >
        <img
          alt="WelcomeImage"
          src={String(useCase.img)}
          style={{ width: "100%", borderRadius: 15 }}
        ></img>
      </Box>
    </Container>
  );
};

export default UseCase;
