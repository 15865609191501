import {
  Avatar,
  Box,
  Container,
  Grid,
  Icon,
  ScopedCssBaseline,
  ThemeProvider,
  Typography,
} from "@mui/material";
import * as React from "react";
import DarkTheme from "../controller/DarkTheme";
import WhiteTheme from "../controller/WhiteTheme";
import LoadingScreen from "./LoadingScreen";

type FeatureSectionProps = {
  pathToJson: string;
  imageSide: string;
  imageRatio: number;
  theme: string;
};

type FeatureSectionData = {
  title: string;
  img: string;
  features: FeatureData[];
};

type FeatureData = {
  title: string;
  img: string;
  icon: string;
  paragraph: string;
};

const FeatureSection = ({
  pathToJson,
  imageSide,
  imageRatio,
  theme,
}: FeatureSectionProps) => {
  const [json, setJson] = React.useState<FeatureSectionData>();
  React.useEffect(() => {
    fetch(pathToJson)
      .then((resJson) => resJson.json())
      .then((res) => setJson(res));
  }, []);

  if (json) {
    const getComponent = () => {
      return (
        <Container
          sx={{
            flex: 1,
            display: "flex",
            backgroundColor: "background.default",
            flexDirection: "column",
            py: {
              xs: 8,
              md: 10
            },
          }}
        >
          <Typography
            sx={{
              pb: {
                xs: 3,
                md: 10
              },
              fontSize: {
                xs: "h4.fontSize",
                md: "h3.fontSize"
              }
            }}
            alignSelf={"center"}
            variant="h3"
            textTransform={"uppercase"}
          >
            {json.title}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: imageSide === "left" ? "row" : "row-reverse",
              },
            }}
          >
            <Box
              sx={{
                flex: imageRatio,
                display: "flex",
                alignItems: "center",
                py: 3,
                pl: {
                  md: imageSide === "left" ? 0 : 5
                },
                pr: {
                  md: imageSide === "left" ? 5 : 0
                }
              }}
            >
              <img alt="featureImage" src={String(json.img)} width="100%"></img>
            </Box>
            <Box
              color="primary"
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                px: 2,
              }}
            >
              <Grid container spacing={5}>
                {json.features.map((feature, ind) => {
                  return (
                    <Grid key={ind} item xs={12} sm={6}>
                      {feature.icon ? (
                        <Avatar
                          sx={{
                            width: 50,
                            height: 50,
                            backgroundColor: "primary.main",
                          }}
                        >
                          <Icon sx={{ color: "white" }} fontSize="large">
                            {feature.icon}
                          </Icon>
                        </Avatar>
                      ) : (
                        <Avatar alt="avatar_image" src={feature.img}></Avatar>
                      )}
                      <Typography
                        textTransform={"uppercase"}
                        variant="h5"
                        mt={2}
                        mb={2}
                        color="text.secondary"
                      >
                        {feature.title}
                      </Typography>
                      <Typography variant="subtitle1" color="text.primary">
                        {feature.paragraph}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Container>
      );
    };
    if (theme === "dark") {
      return <DarkTheme>{getComponent()}</DarkTheme>;
    } else if (theme === "white") {
      return <WhiteTheme>{getComponent()}</WhiteTheme>;
    } else {
      return getComponent();
    }
  } else {
    return <LoadingScreen></LoadingScreen>;
  }
};
export default FeatureSection;
