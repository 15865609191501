import { Box } from "@mui/material";
import * as React from "react";
import FeatureSection from "../components/FeatureSection";
import IntroductionSection from "../components/IntroductionSection";

const Welcome = () => {
  return (
    <Box>
      <IntroductionSection pathToJson="json/features/Welcome.json"></IntroductionSection>
      <FeatureSection
        imageSide="left"
        theme="dark"
        imageRatio={0.6}
        pathToJson="json/features/Manager.json"
      ></FeatureSection>
      <FeatureSection
        imageSide="right"
        theme="light"
        imageRatio={0.6}
        pathToJson="json/features/App.json"
      ></FeatureSection>
      <FeatureSection
        imageSide="left"
        theme="white"
        imageRatio={0.6}
        pathToJson="json/features/Token.json"
      ></FeatureSection>
    </Box>
  );
};

export default Welcome;
