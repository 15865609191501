import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../components/Logo";
import { ButtonBase } from "@mui/material";

type Page = {
  name: string;
  link: string;
};

const pages = [
  { name: "Use Cases", link: "/UseCases" },
  { name: "About Us", link: "/About" },
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <ButtonBase href="/Home">
              <Logo size="big" width={160} color="black"></Logo>
            </ButtonBase>
          </Box>

          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignContent: "center",
            }}
          >
            <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                    <ButtonBase href={page.link}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </ButtonBase>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: "flex", md: "none" },
                justifyContent: "center",
              }}
            >
              <ButtonBase href="/Home">
                <Logo size="small" width={70} color="black"></Logo>
              </ButtonBase>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "center",
                display: { xs: "none", md: "flex" },
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page.name}
                  href={page.link}
                  sx={{ my: 2, display: "block", color: "text.secondary" }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>

            <Box
              sx={{
                flexGrow: {
                  xs: 1,
                  md: 0
                },
                justifyContent: "flex-end",
                display: "flex",
                alignSelf: "center",
                justifySelf: "end"
              }}
            >
              <Button
                variant="contained"
                href="https://manager.mintharbor.io/Login"
                sx={{ color: "white", display: { xs: "flex", md: "none" } }}
              >
                Manager
              </Button>
              <Button
                href="https://manager.mintharbor.io/Login"
                variant="contained"
                sx={{ color: "white", display: { xs: "none", md: "flex" } }}
              >
                Manager
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
