import * as React from "react";

type LogoProps = {
  size: string;
  color: string;
  width: number | string;
};

const Logo = ({ size, color, width }: LogoProps) => {
  //const logo = require('/images/logo_' + size + '.png')
  return (
    <img
      alt="logo"
      className="img-responsive"
      width={width}
      src={String("/images/logos/mh_logo_" + size + "_" + color + ".png")}
    ></img>
  );
};

export default Logo;
