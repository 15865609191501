import { Box, Button, Container, Grid, Typography } from "@mui/material";
import * as React from "react";
import LoadingScreen from "./LoadingScreen";

type IntroductionSectionProps = {
  pathToJson: string;
};

type IntroductionSectionText = {
  title: string;
  paragraph: string;
  button: string;
  img: string;
};

const IntroductionSection = ({ pathToJson }: IntroductionSectionProps) => {
  const [json, setJson] = React.useState<IntroductionSectionText>();
  React.useEffect(() => {
    fetch(pathToJson)
      .then((resJson) => resJson.json())
      .then((res) => setJson(res));
  }, []);

  if (json != null) {
    return (
      <Container
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column-reverse" },
        }}
      >
        <Box
          sx={{
            flex: 3,
            px: {
              xs: 2,
              md: 5,
              lg: 10,
            },
            py: {
              xs: 2,
              md: 5,
              lg: 10,
            },
            pb: {
              xs: 8,
            },
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            textAlign: {
              xs: "center",
              md: "left",
            },
            alignItems: {
              xs: "center",
              md: "flex-start",
            },
          }}
        >
          <Typography variant="h1" color={"text.secondary"} sx={{ mb: 2 }}>
            {json.title}
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            {json.paragraph}
          </Typography>
          <Button
            href="https://manager.mintharbor.io/Login"
            variant="contained"
            size="large"
          >
            {json.button}
          </Button>
        </Box>
        <Box
          sx={{
            flex: 4,
            px: {
              xs: 5,
              md: 0,
            },
            pt: {
              xs: 5,
              md: 3,
            },
          }}
        >
          <img
            alt="WelcomeImage"
            src={String(json.img)}
            style={{ width: "100%" }}
          ></img>
        </Box>
      </Container>
    );
  } else {
    return <LoadingScreen></LoadingScreen>;
  }
};

export default IntroductionSection;
