import {
  Box,
  ButtonBase,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import * as React from "react";
import LoadingScreen from "../components/LoadingScreen";
import Logo from "../components/Logo";

function Copyright() {
  return (
    <Typography variant="body2" color="secondary.contrastText">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        MintHarbor
      </Link>{" "}
      {"2022"}
      {"."}
    </Typography>
  );
}

type ColumnesText = {
  columnes: ColumnText[];
};

type ColumnText = {
  name: string;
  links: LinkText[];
};

type LinkText = {
  name: string;
  link: string;
};

const Footer = () => {
  const [json, setJson] = React.useState<ColumnesText>();
  React.useEffect(() => {
    fetch("json/Footer.json")
      .then((resJson) => resJson.json())
      .then((res) => setJson(res));
  }, []);
  //TODO: the quick links should come from the same field as navigation
  if (json) {
    return (
      <Box
        sx={{
          backgroundColor: "secondary.main",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            pt: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                flex: 0.2,
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              <Logo color="white" width="200" size="medium" />
            </Box>
            <Grid container spacing={2} sx={{ flex: 1 }}>
              {json.columnes.map((column, ind) => (
                <Grid
                  item
                  key={ind}
                  xs={6}
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    px: 3,
                    alignItems: "center",
                  }}
                >
                  <Typography
                    color={"secondary.contrastText"}
                    fontWeight={"bold"}
                    mt={2}
                    mb={1}
                  >
                    {column.name}
                  </Typography>
                  {column.links.map((link, ind) => (
                    <Link
                      key={ind}
                      href={link.link}
                      color="secondary.contrastText"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            component="footer"
            sx={{
              pb: 2,
            }}
          >
            <Box
              sx={{
                borderTop: "solid 1px rgba(255,255,255,0.1)",
                mt: 5,
                mx: 2,
              }}
            />
            <Container
              maxWidth="sm"
              sx={{
                textAlign: "center",
                pt: 2,
              }}
            >
              <Copyright />
            </Container>
          </Box>
        </Container>
      </Box>
    );
  } else {
    return <LoadingScreen></LoadingScreen>;
  }
};
export default Footer;
