import { createTheme, ThemeOptions } from "@mui/material/styles";
import { defaultThemeOptions } from "./defaultTheme";

export const darkThemeOptions: ThemeOptions = {
  ...defaultThemeOptions,
  palette: {
    primary: {
      main: "#52784f",
    },
    secondary: {
      main: "#52784f",
    },
    background: {
      default: "#3B3B3B",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#FFFFFF",
    },
  },
};

export const darkTheme = createTheme(darkThemeOptions);
