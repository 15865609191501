import { Box } from "@mui/system";
import * as React from "react";
import LoadingScreen from "../components/LoadingScreen";
import UseCase from "../components/UseCase";

export type UseCaseText = {
  title: string;
  description: string;
  button: string;
  mhIcon: boolean;
  img: string;
};

type UseCasesText = {
  useCases: UseCaseText[];
  img: string;
};

const UseCases = () => {
  const [json, setJson] = React.useState<UseCasesText>();
  React.useEffect(() => {
    fetch("json/UseCases.json")
      .then((resJson) => resJson.json())
      .then((res) => setJson(res));
  }, []);
  console.log(json);

  if (json) {
    return (
      <div>
        {json.useCases.map((useCase, ind) => (
          <UseCase
            key={"UseCase" + ind}
            imageSide={ind % 2 == 0 ? "left" : "right"}
            useCase={useCase}
          ></UseCase>
        ))}
        <Box sx={{ display: "flex" }}>
          <img
            src={String(json.img)}
            alt="DesktopRender"
            style={{ width: "100%" }}
          ></img>
        </Box>
      </div>
    );
  } else {
    return <LoadingScreen></LoadingScreen>;
  }
};

export default UseCases;
