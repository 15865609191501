import { ScopedCssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { whiteTheme } from "../whiteTheme";

interface WhiteThemeProps {
  children: JSX.Element;
}

const WhiteTheme: React.FC<WhiteThemeProps> = ({ children }) => {
  return (
    <ThemeProvider theme={whiteTheme}>
      <ScopedCssBaseline>{children}</ScopedCssBaseline>
    </ThemeProvider>
  );
};

export default WhiteTheme;
