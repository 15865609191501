import { CssBaseline, GlobalStyles } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import About from "./controller/About";
import Footer from "./controller/Footer";
import Manager from "./controller/Manager";
import Pricing from "./controller/Pricing";
import ResponsiveAppBar from "./controller/ResponsiveAppBar";
import Teaser from "./controller/Teaser";
import UseCases from "./controller/UsesCases";
import Welcome from "./controller/Welcome";
import { defaultTheme } from "./defaultTheme";
function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline></CssBaseline>
      <GlobalStyles
        styles={{ ".img-responsive": { maxWidth: "100%", height: "auto" } }}
      />
      <Router>
        <ResponsiveAppBar></ResponsiveAppBar>
        <Routes>
          <Route path="/Home" element={<Welcome />}></Route>
          <Route path="/About" element={<About />}></Route>
          <Route path="/UseCases" element={<UseCases />}></Route>
          {/*<Route path="/Pricing" element={<Pricing />}></Route>*/}
          <Route path="/*" element={<Navigate to={"/Home"}></Navigate>}></Route>
        </Routes>
        <Footer></Footer>
      </Router>
    </ThemeProvider>
  );
}

export default App;
