import { Email, LinkedIn } from "@mui/icons-material";
import {
  Avatar,
  Box,
  ButtonBase,
  Container,
  Grid,
  ScopedCssBaseline,
  ThemeProvider,
  Typography,
} from "@mui/material";
import * as React from "react";
import LoadingScreen from "../components/LoadingScreen";
import Logo from "../components/Logo";
import { darkTheme } from "../darkTheme";
import DarkTheme from "./DarkTheme";

type AboutText = {
  vision: VisionText;
  team: MemberText[];
  roadmap: RoadmapText[];
};

type RoadmapText = {
  icon: string;
  eta: string;
  title: string;
};

type VisionText = {
  title: string;
  description: string;
};

type MemberText = {
  name: string;
  title: string;
  description: string;
  image: string;
  socials: SocialText[];
};

type SocialText = {
  name: string;
  link: string;
};

function getSocials(social: SocialText) {
  if (social.name === "email") {
    return (
      <ButtonBase href={social.link}>
        <Email
          color="primary"
          style={{ width: "100%", height: "100%" }}
        ></Email>
      </ButtonBase>
    );
  } else if (social.name === "linkedin") {
    return (
      <ButtonBase href={social.link}>
        <LinkedIn
          color="primary"
          style={{ width: "100%", height: "100%" }}
        ></LinkedIn>
      </ButtonBase>
    );
  }
}

const About = () => {
  const [json, setJson] = React.useState<AboutText>();
  React.useEffect(() => {
    fetch("json/About.json")
      .then((resJson) => resJson.json())
      .then((res) => setJson(res));
  }, []);
  if (!json) {
    return <LoadingScreen></LoadingScreen>;
  }
  return (
    <Box sx={{ flexDirection: "column", mb: 5 }}>
      <Container
        maxWidth="lg"
        sx={{
          mb: 10,
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box sx={{ width: 300 }}>
          <Logo color="black" size="small" width={"100%"}></Logo>
        </Box>
        <Box sx={{ height: 4, backgroundColor: "black", width: 200 }}></Box>
        <Typography mt={2} flex={2} variant={"h1"}>
          Vision
        </Typography>
        <Typography mt={2} flex={2} variant={"h5"}>
          {json.vision.title}
        </Typography>
        <Typography textAlign={"justify"} mt={2} variant={"body1"}>
          {json.vision.description}
        </Typography>
      </Container>
      <DarkTheme>
        <Box sx={{ py: 5 }}>
          <Box sx={{ display: "flex", justifyContent: "center", pb: 5 }}>
            <Typography variant={"h1"}>Team</Typography>
          </Box>
          <Container maxWidth="xl">
            <Grid container spacing={5}>
              {json.team.map((member, ind) => (
                <Grid key={ind} item xs={12} md={6}>
                  <Grid />
                  <Box
                    sx={{
                      flexDirection: { xs: "column", lg: "row" },
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        p: { xs: 5, md: 0 },
                        mb: { md: 2 },
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <Avatar
                        sx={{ height: "100%", width: "100%" }}
                        src={member.image}
                      ></Avatar>
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        p: 3,
                        pt: { xs: 0, lg: 3 },
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="h5">{member.name}</Typography>
                      <Typography variant="h6">{member.title}</Typography>
                      <Typography>{member.description}</Typography>
                      <Grid container spacing={6} pt={3}>
                        {member.socials.map((social) => (
                          <Grid item xs={3} md={3}>
                            {getSocials(social)}
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </DarkTheme>
      <Box sx={{ display: "flex", justifyContent: "center", pb: 5 }}>
        <Typography variant={"h1"} mt={5}>
          Roadmap
        </Typography>
      </Box>
      <Container
        sx={{
          my: 5,
          display: "flex",
          flexDirection: { xs: "row", md: "column" },
        }}
      >
        <Box
          sx={{ flexDirection: { xs: "column", md: "row" }, display: "flex" }}
        >
          {json.roadmap.map((item, ind) => (
            <Box key={ind} sx={{ flex: 1, px: 10 }}>
              <img
                alt={"image" + ind}
                src={String(item.icon)}
                width="100%"
              ></img>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            mt: 2,
            width: "100%",
            height: 4,
            backgroundColor: "secondary.main",
          }}
        ></Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          {json.roadmap.map((_, ind) => (
            <Box
              key={ind}
              sx={{ flex: 1, display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  backgroundColor: "primary.main",
                  height: 10,
                  width: 10,
                  borderRadius: 5,
                  mt: -0.75,
                }}
              ></Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{ flexDirection: { xs: "column", md: "row" }, display: "flex" }}
        >
          {json.roadmap.map((item, ind) => (
            <Box
              key={ind}
              sx={{
                flex: 1,
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant={"body1"}>{item.eta}</Typography>
              <Typography textAlign={"center"} variant={"h6"}>
                {item.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default About;
