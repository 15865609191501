import { createTheme, ThemeOptions } from "@mui/material/styles";
import { defaultThemeOptions } from "./defaultTheme";

export const whiteThemeOptions: ThemeOptions = {
  ...defaultThemeOptions,
  palette: {
    primary: {
      main: "#52784f",
    },
    secondary: {
      main: "#3B3B3B",
      light: "#3B3B3B",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#FFFFFF",
    },
    text: {
      primary: "#000000",
      secondary: "#3B3B3B",
    },
  },
};

export const whiteTheme = createTheme(whiteThemeOptions);
